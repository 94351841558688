.activeButton {
  background: #000;
  color: white;
  padding: 10px 25px;
  border: 2px solid #000000;
  font-weight: 300;
  font-size: 16.8069px;
  margin: 0 10px 35px 0;
  transition: .4s;
}

#detail {
  padding-top: 100px;

  .detail--general {
    display: flex;
    justify-content: space-between;

    &__group {
      width: 30%;
      position: sticky;
      top: 100px;
      height: 100%;
      overflow-y: scroll;
      max-height: 80vh;

      h1 {
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        color: #000000;
        display: flex;
        align-items: center;
        padding-bottom: 80px;

        span {
          padding-left: 10px;
        }
      }

      h2 {
        font-weight: 400;
        font-size: 32.3px;
        color: #575757;
      }

      &--btn {
        padding: 10px 25px;
        background: transparent;
        border: 2px solid #000000;
        font-weight: 300;
        font-size: 16.8069px;
        color: #000;
        margin: 0 10px 35px 0;
        transition: .4s;

        &:hover {
          transform: scale(1.1);
        }


      }

      &--btns {
        padding: 10px 25px;
        background: transparent;
        border: 2px solid black;
        margin: 0 10px 35px 0;
        font-weight: 300;
        font-size: 16.8069px;
        color: #000;
        transition: .4s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &__content {
      width: 70%;

      &--group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          padding-left: 10px;

          h3 {
            font-weight: 500;
            font-size: 18px;
            color: #252525;
          }

          h4 {
            font-weight: 300;
            font-size: 18px;
            color: #252525;
          }
        }
      }

      h5 {
        padding: 100px 0 60px 0;
        font-weight: 400;
        font-size: 26.3px;
        line-height: 38px;
        color: #575757;
      }

      &--info {
        display: flex;
        justify-content: space-around;

        div {
          padding: 30px 0;

          h6 {
            font-weight: 700;
            font-size: 20.3px;
            color: #000000;
          }

          p {
            font-weight: 400;
            font-size: 20.3px;
            color: #000000;
            padding: 10px 0;
          }

          ul {
            ol {
              font-weight: 300;
              font-size: 18.3px;
              line-height: 26px;
              color: #000000;
            }
          }
        }
      }

      &--img {
        padding: 20px 0;
      }

      &--info2 {
        h6 {
          font-weight: 700;
          font-size: 20.3px;
          color: #000000;
        }

        p {
          font-weight: 400;
          font-size: 20.3px;
          color: #000000;
          padding: 10px 0;
        }

        ul {
          ol {
            font-weight: 300;
            font-size: 18.3px;
            line-height: 26px;
            color: #000000;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  #detail {
    .detail--general {
      &__content {
        .fer-media {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}


@media (max-width: 1200px) {
  .container {
    width: 90%;
  }
}

@media (max-width: 1024px) {
  #detail {
    .detail--general {
      display: flex;
      justify-content: center;

      &__group {
        width: 0;

        h1 {
          padding: 0;
        }

        .web {
          display: none;
        }

        .media {
          display: block;
          position: fixed;
        }
      }

      &__content {
        width: 100%;

        &--group {
          flex-wrap: wrap;
          justify-content: center;
          text-align: center;

          svg{
            display: none;
          }

        }

      }

    }
  }
}

@media (max-width: 768px) {
  #detail {
    .detail--general {
      &__group {
        .page {
          right: 66px;
          bottom: 50px;
        }
      }

      &__content {
        width: 100%;

        &--group {
          flex-direction: column;

      }
    }
  }
  }
}

@media (max-width: 576px) {
  #detail {
    .detail--general {
      &__content {
        &--info {
          flex-wrap: wrap;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  #detail {
    .detail--general {
      &__group {
        .page {
          right: 24px;
          bottom: 20px;
        }
      }
    }
  }
}

