* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

.container {
    width: 90%;
    margin: 0 auto;
}

body, html {
    scroll-behavior: smooth;
    font-family: 'Roboto Condensed', sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: transparent;

}

::-webkit-scrollbar-thumb {
    background: #B66A20;
    border-radius: 30px;
}


button {
    border: none;
    cursor: pointer;
    transition: .4s;
}


.blocks {
    width: 600px;
    padding: 300px 0 0 0;
    height: 600px;
    background: #fdfdfe;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.blocks h2 {
    padding: 28px 0;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 23px;
    text-transform: uppercase;
    color: #B59906;
}

.loading {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 270px 0 0 0;
    height: 600px;
    align-items: center;
    position: relative;
}

.loading div {
    position: absolute;
    width: 30px;
    height: 10px;
    background: #B66A20;
    border-radius: 10px;
    margin: 0 10px;
    animation: loading 1s infinite linear;
}

.loading-one {
    left: 270px;
}

.loading-three {
    right: 270px;
}

@keyframes loading {
    0% {
        width: 10px;
        height: 30px;
    }
}

@media (max-width: 1200px) {
    .blocks {
        width: 329px;
        justify-content: end;
    }

    .loading {
        width: 329px;
    }
}

@media (max-width: 1024px) {
    .loading {
        margin: 0 auto;
        align-items: start;
    }

    .blocks {
        width: 100%;
        justify-content: start;
        height: 710px;
    }
}


@media (max-width: 450px) {
    .blocks {
        padding: 0;
    }

    .loading {
        width: 100%;
        padding: 80px 0 0 0;
    }

    .loading-one {
        left: 220px;
    }
    .loading-three{
        right: 220px;
    }
}
