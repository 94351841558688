#layouts {
  background: #e7ebea;
  padding: 100px 0;
  position: relative;
  .layouts--bg {
    //width: 100%;
    //font-weight: 700;
    //font-size: 290.589px;
    //line-height: 420px;
    //text-transform: uppercase;
    //color: rgba(87, 87, 87, 0.06);
    //overflow: hidden;
    position: absolute;
    right: 0;
    top: -70px;
  }

  .layouts--general {
    padding-top: 20px;

    &__title {
      font-family: 'Myflora Personal Use', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 50px;
      color: #CC8A57;
      text-transform: uppercase;
      padding-bottom: 30px;
    }

    &__desc {
      font-weight: 400;
      width: 538px;
      font-size: 17px;
      color: rgba(51, 51, 51, 0.72);
      padding-left: 10px;
    }

    &__group {
      display: flex;
      justify-content: space-between;
      padding-top: 60px;

      div {
        p {
          font-weight: 400;
          font-size: 23.28px;
          line-height: 34px;
          color: #000000;
          padding-top: 10px;
        }
      }

      &--block {
        width: 443px;
        height: 455px;
        background: rgba(0, 0, 0, 0.84);
        backdrop-filter: blur(15px);
        position: relative;
        transition: .4s;

        &:hover > .img {
          transition: .4s;
          z-index: -1;
          opacity: .4;
        }

        img {
          position: absolute;
          z-index: 5;
        }

        button {
          position: absolute;
          width: 168px;
          height: 56px;
          background: #FFFFFF;
          border: 1px solid #EFEFEF;
          border-radius: 32px;
          font-weight: 300;
          font-size: 16.8069px;
          color: #363636;
          top: 200px;
          left: 150px;
          transition: .4s;

          &:hover {
            transform: scale(1.1);
          }


        }
      }
    }
  }
}

// media

@media (max-width: 1440px) {
  #layouts {
    .layouts--general {
        .block {
          margin: 20px;
        }
    }
  }
  #layouts .layouts--general__group--block {
    width: 400px;
    height: 400px;
  }
  #layouts .layouts--general__group--block button {
    top: 181px;
    left: 117px;
  }
}
@media (max-width: 1300px) {
  #layouts .layouts--general__group {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  #layouts {
    .layouts--general {
      &__group {
        &--block {
          &:hover > .img {
            transition: .0s;
            z-index: 0;
            opacity: 1;
          }

          button {
            background: transparent;
            top: 461px;
            left: 272px;
            border-radius: 0;
            border: 2px solid #000001;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #layouts {
    .layouts--bg {
      font-size: 200px;
    }
    .layouts--general {
      &__title {
        font-size: 35px;
      }
    }
  }
  #layouts .layouts--general__group--block {
    width: 443px;
    height: 455px;
  }
}

@media (max-width: 576px) {
  #layouts {
    .layouts--bg {
      font-size: 140px;
    }

    .layouts--general {
      &__desc {
        width: 90%;
      }

      &__group {
        &--block {
          width: 389px;
          height: 397px;

          button {
            top: 407px;
            left: 215px;
          }

        }
      }
    }
  }
}

@media (max-width: 450px) {
  #layouts {

    position: relative;
    padding: 0 0 20px 0;

    .layouts--bg {
      font-size: 100px;
      top: -69px;
    }

  }
  #layouts .layouts--general {
    padding-top: 72px;
  }
}






@media (max-width: 400px) {
  #layouts {
    .layouts--bg {
      font-size: 90px;
    }

    .layouts--general {
      &__group {
        &--block {
          width: 350px;
          height: 356px;

          button {
            top: 370px;
            left: 177px;
          }

        }
      }
    }
  }
}

@media (max-width: 320px) {
  #layouts {
    .layouts--bg {
      font-size: 90px;
    }

    .layouts--general {
      &__group {
        &--block {
          width: 289px;
          height: 297px;

          button {
            top: 306px;
            left: 119px;
          }
        }
      }
    }
  }
}