#header {
  position: sticky;
  top: 0;
  z-index: 100;
  //padding: 20px 0;
  height: 90px;
  background: #e9eaed;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/*/////*/


.menu-line {
  width: 70%;
  height: 3px;
  margin: 0 auto;
  background: #B66A20;
}

.block-menu {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 54px auto;
  align-items: center;
}

.block-menu a {
  font-family: 'Jost', sans-serif;
  font-weight: 300;
  font-size: 26.31px;
  text-align: right;
  color: #000000;
}

.block-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 20px auto;
}

.block-nav a {
  font-family: 'Jost', sans-serif;
  font-weight: 300;
  font-size: 17.41px;
  color: #575757;
}

.block-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  margin: 20px auto;
}


@media (max-width: 576px) {
  .menu-line{
    display: none;
  }

  .block-menu{
    flex-wrap: wrap;
    justify-content: center;
  }

  .block-menu a{
    padding: 10px ;
  }
}

@media (max-width: 450px) {
  .block-menu {
    flex-direction: column;
    justify-content: center;
    margin: 0;
    width: 100%;
  }
  .block-menu a {
    padding: 20px 0;
  }

  .block-nav {
    flex-direction: column;
    justify-content: center;
    margin: 0;
    width: 100%;
  }

  .block-nav a {
    padding: 20px 0;
  }

}