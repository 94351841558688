#project {
  padding-top: 100px;
  margin-bottom: 40px;

  .project {
    h1 {
      display: flex;
      align-items: center;
      padding-bottom: 50px;

      span {
        padding-left: 4px;
      }
    }

    &--title {
      font-family: 'Myflora Personal Use', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 50px;
      color: #CC8A57;
      padding-bottom: 30px;
    }

    p {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 32px;
      color: #333333;
      padding-bottom: 20px;
    }
  }
}

@media(max-width: 768px) {
  #project .project--title {
    font-size: 40px;
  }
  #project {
    .project {
      p {
        font-size: 17px;
      }
    }
  }
}