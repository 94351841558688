// fonts
//@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/myflora-personal-use");
// fonts

// components
@import "header";
@import "hero";
@import "admin";
@import "infrast";
@import "layouts";
@import "about";
@import "partners";
@import "contact";
@import "detail";
@import "footer";
@import "gallery";
@import "project";
@import "whatsapp";
// components

:root {
  --main-bd-cr: var(--blue-9);
  --main-txt-cr: var(--gray-0);
}

button {
  font: inherit;
}

.page {
  color: var(--main-bd-cr);
  background-color: var(--main-txt-cr);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-sans);
  position: fixed;
  right: 119px;
  bottom: 80px;
}

.dropup {
  position: relative;
}

.dropup ul {
  border: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.dropup:hover .dropup__list {
  --dropup__list_max-ht: 18em;
  border: 2px solid;
}

.dropup:hover .dropup__btn {
  color: var(--main-bd-cr);
  background-color: var(--main-txt-cr);
}

.dropup__list {
  transition: max-height 180ms ease-in-out;
  color: var(--main-bd-cr);
  position: absolute;
  bottom: 100%;
  left: 0;
  max-height: var(--dropup__list_max-ht, 0);
  overflow: hidden;
  width: 195px;
  //height: 100%;
  background: #ffffff;
  //overflow-y: scroll;
  border: 1px solid #000000;
  border-radius: 26px 26px 0 0;
}

.dropup__item:hover {
  background-color: var(--gray-2);
}

.dropup__item {
  text-align: center;
  margin: 5px 0;
  border-bottom: 1px solid black;
}

.dropup__link {
  font-weight: 300;
  font-size: 18px;
  color: #575757;
  text-align: center;
  width: 90%;
  margin: 10px 0;
  margin-bottom: 10px;
}

.primary-btn {
  cursor: pointer;
  box-shadow: 0.1em 0.1em 0.3em hsla(0, 0%, 0%, 0.3);
  width: 162px;
  height: 58px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 26px;
  font-weight: 300;
  font-size: 16.8069px;
  line-height: 24px;
  color: #575757;
}

.primary-btn:hover {
  width: 195px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 0 0 26px 26px;
}

.page:hover .primary-btn {
  width: 195px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 0 0 26px 26px;
}

.media {
  display: none;
  position: relative;
  width: 100%;
}

.media--btn {
  position: fixed;
  bottom: 30px;
  left: 10px;
  padding: 15px 25px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 26px;
  font-weight: 300;
  font-size: 16.8069px;
  color: #575757;
}

.blocks {
  width: 600px;
  height: 400px;
  background: red;
}
