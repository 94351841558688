#partners {
  padding: 40px 0;
  position: relative;
  .partners--bg {
    //width: 100%;
    position: absolute;
    width: 854px;
    height: 846px;
    left: 365px;
    top: 200px;
  }

  .partners--general {
    padding-top: 140px;

    &__title {
      font-family: 'Myflora Personal Use', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 50px;
      color: #CC8A57;
      text-transform: uppercase;
      padding-bottom: 30px;
    }

    &__desc {
      font-weight: 400;
      width: 538px;
      font-size: 16px;
      color: rgba(51, 51, 51, 0.72);
      padding-left: 10px;
    }
  }
}



@media (max-width: 1200px)  {
  #partners {
    .partners--bg {
      //font-size: 210.589px;
      width: 700px;
      height: 700px;
      left: 280px;
      top: 172px;
    }
    .partners--general {
      &__title{
        font-size: 57px;
      }
    }
  }
}

@media (max-width: 1024px) {
  #partners {
    .partners--bg {
      position: absolute;
      left: -350px;
      top: 385px;
    }
    .partners--general {
      &__title{
        font-size: 47px;
      }
    }
  }
}

@media (max-width: 992px) {
  #partners {
    .partners--bg {
      font-size: 170.589px;
    }
    .partners--general {
      div{
        flex-wrap: wrap;
        justify-content: center;
        padding: 50px;
      }
    }
  }
}

@media (max-width: 768px) {
  #partners {
    .partners--bg {
      font-size: 135.589px;
    }
  }
  .partners--general {
    &__title{
      padding-top: 40px;
      font-size: 35px;
    }
  }
}

@media (max-width: 576px) {
  #partners {
    .partners--bg {
      font-size: 100.589px;
    }
  }
  .partners--general {
    &__title{
      padding-top: 35px;
    }
  }
}

@media (max-width: 450px) {
  #partners {
    padding: 0;
    position: relative;
    .partners--bg {
      top: -128px;
      font-size: 100px;
    }

    .partners--general {
      padding-top: 30px;
      &__title{
        padding-top: 25px;
      }

      &__block{
        div{
          padding: 20px;
        }
      }
    }
  }

}

@media (max-width: 425px) {
  #partners {
    .partners--bg {
      font-size: 95px;
    }
  }
  .partners--general {
    &__title{
      padding-top: 25px;
    }
  }
}

@media (max-width: 400px) {
  #partners {
    .partners--bg {
      font-size: 90px;
    }
    .partners--general {
      &__title{
        font-size: 37px;
        padding-top: 40px;
      }
    }
  }
}

@media (max-width: 375px) {
  #partners {
    .partners--bg {
      font-size: 80px;
    }
    .partners--general {
      &__title{
        font-size: 37px;
      }
    }
  }
}

@media (max-width: 320px) {
  #partners {
    .partners--bg {
      font-size: 75px;
    }
    .partners--general {
      &__title{
        font-size: 37px;
      }
    }
  }
}