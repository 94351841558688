#infrast {
  background: #e7ebea;

  .infrast--bg {
    position: absolute;
    left: 0;
    padding-top: 170px;
    //width: 764px;
    height: 1058px;
  }

  .infrast--general {
    padding-top: 70px;

    &__title {
      font-family: 'Myflora Personal Use', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 50px;
      color: #CC8A57;
      text-transform: uppercase;
      padding-bottom: 30px;
    }

    &__desc {
      font-weight: 400;
      width: 558px;
      font-size: 17px;
      color: rgba(51, 51, 51, 0.72);
      padding-left: 10px;
    }

    &__icons {
      &--one {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

  }
}

@media (max-width: 1200px) {
  #infrast {
    .infrast--general {
      &__icons {
        &--one {
          grid-template-columns:1fr 1fr 1fr;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  #infrast {
    .infrast--bg {
      font-size: 200px;
    }
  }
}

@media (max-width: 768px) {
  #infrast {
    .infrast--bg {
      font-size: 150px;
    }

    .infrast--general {
      &__desc {
        width: 90%;
      }

      &__title {
        font-size: 35px;
      }

      &__icons {
        &--one {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}


@media (max-width: 576px) {
  #infrast {
    .infrast--general {
      &__icons {
        &--one {
          &__block {
            width: 90%;
          }

        }
      }
    }
  }
}

@media (max-width: 768px) {
  #infrast {
    .infrast--bg {
      font-size: 100px;
    }
  }
}

@media (max-width: 450px) {
  #infrast {
    position: relative;
    padding: 0;

    .infrast--bg {
      top: -142px;
    }

    .infrast--general {
      padding-top: 55px;


      &__icons {
        padding: 0;
        justify-content: center;
        width: 100%;

        &--one {
          margin: 0;

          &__block {
            padding: 0;
          }
        }
      }

      .infrast--btn {
        position: absolute;
        bottom: -28px;
        justify-content: center;
        width: 90%;
        z-index: 2;
      }
    }
  }
}