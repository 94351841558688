#footer {
  background: #152028;
  padding: 40px 0 30px 0;

  .footer--general {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__route {
      padding: 30px 0;

      a {
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 0.035em;
        padding: 0 30px;
        color: #FDFDFD;
      }
    }

    &__icons {
      display: flex;
      align-items: center;
      padding: 0 0 30px 0;

      div {
        width: 48px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #B66A20;
        font-size: 22px;
        margin: 0 20px;
        cursor: pointer;
        transition: .4s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &__line {
      width: 389px;
      height: 2px;
      background: #CC8A57;
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        text-align: center;
        opacity: 0.5;
        color: #70767A  ;
        padding: 20px 30px 0 30px;
      }
    }
  }
}

@media (max-width: 992px) {
  #footer {
    padding: 0 0 40px 0;

    .footer--general {
      &__route {
        a {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #contact .contact--block {
    height: 340px;
  }
  #footer {
    .footer--general {
      &__footer {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 400px) {
  #footer {
    .footer--general {
      &__line {
        width: 90%;
      }
    }
  }
}