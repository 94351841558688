#gallery {
  .gallery {
    padding-top: 100px;

    &--text {
      width: 30%;
      position: sticky;
      top: 100px;
      height: 100%;
      max-height: 80vh;
      z-index: 10;
      overflow-y: scroll;

      h2 {
        display: flex;
        align-items: center;
        padding-bottom: 18px;

        span {
          font-weight: 300;
          font-size: 16.8069px;
          color: #000000;
          padding-left: 11px;

          &:hover {
            color: blue;
          }
        }
      }

      h1 {
        font-weight: 400;
        font-size: 32.3px;
        color: #575757;
        padding: 32px 0 11px 0;
      }


      &__btn {
        padding: 12px 30px;
        font-weight: 300;
        font-size: 16.8069px;
        color: #000000;
        background: transparent;
        border: 2px solid #000001;
        cursor: pointer;
        margin: 10px 20px 10px 0;
      }
    }

    &--img {
      h3 {
        font-weight: 400;
        font-size: 32.3px;
        color: #575757;
        padding-left: 15px;
      }

      h2 {
        padding-top: 115px;
        font-weight: 400;
        font-size: 32.3px;
        color: #575757;
        padding-left: 15px;
      }

      img {
        padding: 15px;
      }

    }
  }
}

// media

@media (max-width: 1200px) {
  #gallery {
    .gallery {
      &--text {
        &__btn {
          width: 85%;
          padding: 10px 0;
        }

        h1 {
          font-size: 25px;
        }
      }
    }
  }
}


@media (max-width: 1024px) {
  #gallery {
    .gallery {
      &--text {
        width: 0;

        .media {
          display: block;

          button {
            z-index: 100;
          }
        }

        &__btn {
          font-size: 14px;
        }

        h1 {
          font-size: 20px;
        }
      }

      &--img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 992px) {
  #gallery {
    .gallery {
      justify-content: center;

      &--text {
        h2 {
          padding: 0;
        }

      }
    }
  }
}

@media (max-width: 450px) {
  #gallery {
    .gallery {
      &--text {
        .page {
          right: 33px;
          bottom: 30px;
        }
      }
    }
  }

}

@media (max-width: 450px) {
  #gallery {
    .gallery {
      &--text {
        .page {
          right: 13px;
          bottom: 30px;
        }
      }
    }
  }

}