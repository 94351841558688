.w-link {
  position: fixed;
  bottom: "20px";
  right: "32px";
  border-radius: "50%";
  z-index: "10";
  &:active{
    opacity: .7;
  }
}

.w-circle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0px 4px 19.8px 0px #0000001F;

}
