#about {
  background: #e7ebea;
  position: relative;
  padding: 45px 0;

  .about {
    &--title {
      position: absolute;
      left: -68px;
      top: 0;
      //font-weight: 700;
      //font-size: 290.589px;
      //color: rgba(87, 87, 87, 0.06);
    }

    &--block {
      &__text {
        h2 {
          font-family: 'Myflora Personal Use', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 50px;
          padding-bottom:40px;
          color: #CC8A57;
          text-transform: uppercase;
        }

        &--line {
          padding-left: 70px;

          div {
            width: 4px;
            height: 110px;
            background: #B59906;
          }

          p {
            padding-left: 18px;
            width: 671px;
            font-weight: 300;
            font-size: 17px;
            line-height: 35px;
            color: #343232;

          }
        }

        &--svg {
          padding: 55px 0 0 50px;

          &__one {
            margin: 0 30px;
            width: 168px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 218px;
            justify-content: space-between;

            h3 {
              font-weight: 300;
              font-size: 44px;
              line-height: 32px;
              color: #292929;
            }

            span {
              width: 103px;
              font-weight: 300;
              font-size: 20px;
              line-height: 24px;
              text-align: center;
              color: #292929;
            }
          }
        }
      }


    }

  }
}

// media

@media (max-width: 1200px) {
  #about {
    padding-top: 80px;

    .about {
      &--title {
        left: 32px;
      }

      &--block {
        flex-direction: column;

        img {
          padding: 30px 0;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  #about {
    .about {
      &--title {
        left: 6px;
      }
    }
  }
}

@media (max-width: 768px) {
  #about {
    .about {
      &--title {
        font-size: 200px;
        top: 33px;
        left: 35px;
      }

      &--block {
        &__text {
          h2 {
            font-size: 35px;
          }
          &--line {
            p {
              width: 90%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  #about {
    .about {
      &--title {
        font-size: 130px;
      }

      &--block {
        &__text {
          &--svg {
            padding: 0;
            flex-wrap: wrap-reverse;
            justify-content: center;
            display: grid;
            gap: 20px;
            grid-template-areas:
             "A A A A   A A A A   A A A A"
    "B B B B   B B B B   B B C C"
    "B B B B   B B B B   B B C C";

            .box-one {
              grid-area: A;
            }
            .box-two {
              grid-area: B;
            }
            .box-three {
              grid-area: C;
            }

            &__one {
              margin: 0 auto;

            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  #about {
    overflow: hidden;
    .about {
      &--title {
        font-size: 100px;
        top: 35px;
        width: 100%;
      }

      &--block {
        &__text {
          &--line {
            padding: 0;

            div {
              display: none;
            }
          }
        }
      }
    }
  }
}