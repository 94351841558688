#hero {
  background: #e7ebea;
  //padding-top: 80px;

  .hero--general {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__group {
      //padding-top: 90px;

      &--content {
        display: flex;
        align-items: center;
        padding: 20px 0 35px 0;

        div {
          width: 10px;
          height: 150px;
          background: #CC8A57;
          margin-right: 30px;
        }

        p {
          font-weight: 300;
          font-size: 17px;
          width: 300px;
          line-height: 24px;
          //width: 243px;
          color: #343232;
        }
      }

      button {
        font-weight: 300;
        font-size: 16.8069px;
        color: #000000;
        padding: 10px 30px;
        background: transparent;
        cursor: pointer;
        transition: .4s;
        border: 2px solid #000000;

        &:hover {
          background: #B59906;
          color: white;
          border: 2px solid #B59906;
          transform: scale(1.1);
        }
      }
    }

    &__img {
      width: 990px;
      height: 585px;
      object-fit: contain;
    }

    &__img2 {
      display: none;
    }
    &__logo {
      margin-left: 100px;
    }

  }

  .hero-text {
    &_title {
      font-family: 'Myflora Personal Use', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 50px;
      line-height: 63px;
      text-transform: uppercase;
      color: #CC8A57;
      padding: 15px 0 13px 0;
      text-align: center;
    }
  }
}

//-- media -- //

//@media (max-width: 2560px) {
//  #hero .hero--general__logo {
//    margin-left: 209px;
//  }
//}

@media (max-width: 1440px) {
  #hero .hero--general__logo {
    margin-left: 101px;
  }
  #hero .hero-text_title {
    font-size: 44px;
  }
  #hero .hero--general__img {
    width: 950px;
    height: 570px;
  }
}

@media (max-width: 1200px) {
  #hero {
    .hero--general {

      &__img {
        width: 75%;
      }
    }
  }
}

@media (max-width: 1024px) {
  #hero {
    .hero--general {

      &__img {
        width: 70%;
      }
    }
  }
  #hero .hero-text_title {
    font-size: 42px;
  }
  #hero .hero--general__logo {
    margin-left: 67px;
  }
}

@media (max-width: 992px) {
  #hero .hero--general__group {
    padding-top: 90px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #hero .hero--general__logo {
    margin-left: -5px;
  }
  #hero .hero-text_title {
    margin-left: -97px;
    font-size: 37px;
    line-height: 43px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  #hero {
    .hero--general {
      flex-direction: column;

      &__img {
        display: none;
      }

      &__img2 {
        display: block;
        width: 90%;
        padding: 20px 0;
      }

      &__logo {
        margin-left: 9%;
        width: 250px;
      }

      &__group {
        button {
          margin: 20px 0;
        }

        &--content {
          div {
            margin-right: 10px;
            display: none;
          }
        }
      }

    }

    .hero-text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      &_title {
        font-size: 40px;
        padding: 32px 0 0 0;
      }
    }
  }
  #contact .contact--text button {
     margin-left: 0;
  }
  #hero .hero-text_title {
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  #hero {
    .hero--general {
      &__group {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__logo {
        margin-left: 26%;
        width: 250px;
      }
    }
  }
}

@media (max-width: 450px) {
  #hero {
    .hero--general {
      &__group {

        .hero-text {
          p {
            width: 100%;
          }

          img {
            //height: 140px;
          }
        }
      }

      &__logo {
        margin-left: 32%;
        width: 148px;
      }
    }
  }
  #hero .hero--general__logo {
    margin-left: 22%;
    width: 229px;
  }
}

@media (max-width: 425px) {
  #hero .hero--general__logo {
    margin-left: 32%;
    width: 148px;
  }
}

@media (max-width: 400px) {
  #hero .hero--general__logo {
    margin-left: 30%;
    width: 148px;
  }
}

@media (max-width: 376px) {
  #hero .hero--general__logo {
    margin-left: 29%;
    width: 148px;
  }
}

