#admin {
  //padding:  0;
  position: relative;
  z-index: 1 ;
  background: #e7ebea;

  .admin {
    &--title {
      position: absolute;
      right: 0;
    }

    h2 {
      font-family: 'Myflora Personal Use', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 50px;
      color: #CC8A57;
      padding-top: 70px;
    }

    &--text {
      display: flex;
      padding: 32px 0 0 35px;


      &__line {
        margin: 15px;
        width: 19px;
        height: 2px;
        background: #B59906;
      }

      &__title {
        h5 {
          width: 464px;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          color: #333333;
          padding-bottom: 12px;
        }

        p {
          width: 916px;
          font-weight: 400;
          font-size: 17px;
          line-height: 32px;
          color: rgba(51, 51, 51, 0.72);
          padding-bottom: 60px;
        }
      }
    }

    &--block {
      padding: 32px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__text {
        display:flex;
        flex-direction:column;
        align-items:start;

        h1 {
          font-family: 'Myflora Personal Use', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 32.2843px;
          line-height: 39px;
          text-transform: uppercase;
          color: #152018;
        }

        h3 {
          padding: 30px 0;
          width: 318px;
          font-weight: 400;
          font-size: 17px;
          line-height: 32px;
          color: rgba(51, 51, 51, 0.72);
        }
        div {
          button {
          padding: 15px 30px;
          font-weight: 300;
          font-size: 16.8069px;
          color: #000000;
          background: transparent;
          border: 2px solid #000001;
          }

          &:hover {
            background: #B59906;
            color: white;
          }
          }
        }
      }
    }
  }
  

@media (max-width: 1200px) {
  #admin {
    .admin {
      &--block {
        img {
          width: 70%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  #admin {
    .admin {
      &--title {
        font-size: 200px;
      }

      &--text {
        &__title {
          p {
            width: 90%;
          }
        }
      }

      &--block {
        img {
          width: 65%;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  #admin {
    .admin {
      &--block {
        flex-direction: column-reverse;

        img {
          width: 100%;
          padding-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #admin {
    .admin {
      h2 {
        font-size: 35px;
      }
      &--title {
        font-size: 140px;
        margin-top: 80px;

        &__line {
          width: 100px;
        }
      }
      &--block {
        &__text {
          h1 {
            font-size: 25px;
          }
          div a button {
            margin-top: 30px;
        }
      }
    }
  }
}
}

@media (max-width: 576px) {
  #admin {
    .admin {
      &--block {
        &__text {
          div a button {
            margin-top: 30px;
          }
        }
      }
      &--text {
        padding: 0;
        &__line{
          width: 170px;
        }
        &__title {
          h5 {
            width: 90%;
          }

          p {
            width: 90%;
          }
          
        }
      }
    }
  }
}

@media (max-width: 450px) {
  #admin {
    padding: 0;

    .admin--title {
      margin-top: 0;
    }

    .admin {
      h2 {
        padding-top: 100px;

      }

      &--block {
        &__text {
          &--btn{
            position: absolute;
            bottom: -30px;
            z-index: 2;
          }
          div a {
            button {
            margin-top: 30px;
            }
          }
        }
      }
    }


  }
}

@media (max-width: 320px) {
  #admin {
    .admin {
      &--block {
        &__text {
          h3 {
            width: 90%;
          }
          div {
            margin-top:30px;
          }
        }
      }
    }
  }
}
