#contact {
  position: relative;
  padding: 60px 0;

  .contact {

    &--title {
      z-index: -1;
      width: 100%;
      position: absolute;
      overflow: hidden;
      left: 0;
      top: 0;
      font-weight: 700;
      font-size: 290.589px;
      color: rgba(87, 87, 87, 0.06);
    }

    &--text {
      h2 {
        font-family: 'Myflora Personal Use', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 50px;
        color: #CC8A57;
        text-transform: uppercase;
        padding-bottom: 101px;
      }

      &__value {
        margin-left: 25px;

        h4 {
          font-weight: 700;
          font-size: 16px;
          text-transform: uppercase;
          color: #252525;
        }

        input {
          outline: none;
          width: 364px;
          height: 40px;
          border-bottom: 0.5px solid #252525;
          font-weight: 400;
          font-size: 16px;
          color: #252525;
          margin-bottom: 50px;
          background: transparent;
        }
      }

      button {
        font-weight: 300;
        font-size: 16.8069px;
        color: #000000;
        background: transparent;
        border: 2px solid #000001;
        padding: 15px 30px;
        margin-left: 20px;

        &:hover {
          background: #B59906;
          color: white;
          border: 2px solid #B59906;
          transform: scale(1.1);
        }
      }

    }

    &--block {
      position: absolute;
      width: 687px;
      height: 460px;
      right: 0;
      bottom: 0;
      background: #152028;
      display: flex;
      flex-direction: column;
      justify-content: start;

      &__title {
        font-weight: 400;
        font-size: 47.28px;
        color: #FFFFFF;
        margin: 24px 0 15px 31px;
      }

      &__one {
        display: flex;
        align-items: center;
        margin: 15px 0 15px 31px;

        a {
          margin-left: 33px;
          width: 233px;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #FDFDFD;
        }
      }
    }

  }
}

@media (max-width: 1024px) {
  #contact {
    .contact {
      &--title {
        font-size: 200px;
        top: 50px;
      }

      &--text {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-bottom: 400px;
      }
    }
  }
}

@media (max-width: 1024px) {
  #contact {
    .contact {
      &--title {
        font-size: 150px;
        top: 80px;
      }

      &--text {
        align-items: center;
        padding-bottom: 425px;
      }

      &--block {
        width: 100%;
        height: 430px;
        align-items: center;
        justify-content: center;

        &__title {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .contact--text {
    h2 {
      font-size: 35px;
      padding-bottom:30px;
    }
  }
}

@media (max-width: 450px) {
  #contact {
    padding: 20px 0;

    .contact {
      &--title {
        font-size: 110px;
        top: -28px;
      }

    }
  }
}

@media (max-width: 425px) {
  #contact {
    .contact {
      &--title {
        font-size: 90px;
        bottom: 0;
      }

      &--text {
        h2 {
          font-size: 35px;
          padding-bottom: 40px;
        }

        &__value {
          width: 100%;

          input {
            width: 90%;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  #contact {
    .contact {
      &--block {
        &__one {
          a {
            width: 250px;
            margin-left: 10px;
          }
        }

      }
    }
  }
}